import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TextArea from '../../../components/textarea';

import { LOCALE } from '../../../bi/constants/hotels';

import styles from '../styles/index.module.scss';

class DescriptionBlock extends Component {
  static propTypes = {
    hotelsService: PropTypes.object.isRequired,
    Description: PropTypes.string.isRequired,
    isNoEdit: PropTypes.bool,
    setRef: PropTypes.func,
    title: PropTypes.string,
    field: PropTypes.string.isRequired,
    locale: PropTypes.string,
  };

  static defaultProps = {
    isNoEdit: false,
    setRef: () => {},
    title: '',
    locale: LOCALE.RU,
    validation: null,
  }

  handleChangeInput = (value, field) => {
    const { hotelsService: { doValidation, changeField } } = this.props;

    changeField(value, field);
    const fields = field.split('.');
    doValidation(value, fields[1]);
  };

  renderEnglishAttention = (label, en) => (
    en && <div className={ styles.english }>{ label }</div>
  );

  render() {
    const { hotelsService, Description, isNoEdit, setRef, title, field, locale } = this.props;
    const { validation } = hotelsService.get();
    const fields = field.split('.');
    const valid = validation[fields[1]];

    return (
      <div className={ styles.description_screen } ref={ ref => setRef(ref) }>
        <h2>{ title }</h2>
        <div className={ styles.description_wrap }>
          <div className={ styles.text_area_wrap }>
            <TextArea
              value={ Description }
              placeholder={ 'Опишите отель согласно шаблону справа' }
              label={ 'Опишите отель согласно шаблону справа' }
              labelTop
              className={ styles.text_area }
              onChange={ value => this.handleChangeInput(value, field) }
              disabled={ isNoEdit }
              valid={ valid }
            />
          </div>
          <div className={ styles.sample_wrap }>
            <h4>Шаблон для заполнения Описания</h4>
            <div className={ styles.sample_item }>
              <div>&lt;h4&gt;Заголовок1&lt;/h4&gt;</div>
              <div>&lt;p&gt;текст1&lt;/p&gt;</div>
            </div>
            <div className={ styles.sample_item }>
              <div>&lt;h4&gt;Заголовок2&lt;/h4&gt;</div>
              <div>&lt;p&gt;текст2&lt;/p&gt; </div>
            </div>
            <h4>Выделение текста</h4>
            <div className={ styles.sample_item }>
              <div>Курсив - &lt;em&gt;курсивный текст&lt;/em&gt;</div>
            </div>
            <div className={ styles.sample_item }>
              <div>Жирный - &lt;b&gt;жирный текст&lt;/b&gt;</div>
            </div>
          </div>
        </div>
        { this.renderEnglishAttention(title, locale === LOCALE.EN) }
      </div>
    );
  }
}

export default DescriptionBlock;

