import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'sw-ui';

import LinkToNewTab from '../../../../components/LinkToNewTab';
import { AllMatchesDialog } from '../AllMatchesDialog';

import styles from '../../styles/search.module.scss';

const LABELS = {
  DELETED: 'Отель удален',
  CITY: 'Город:',
  ADDRESS: 'Адрес:',
  STARS: 'Звезд:',
  BUTTON_SHOW_MATCHES: 'Показать все сопоставления',
};

const HotelItem = ({
  hotelsService,
  item: {
    Id,
    Name,
    City,
    Deleted,
    Address,
    Thumbnail,
    Stars,
    IdAanda,
    IdAcadem,
    IdBooking,
    IdBronevik,
    IdExpedia,
    IdTripcom,
    IdHotelbook,
    IdOstrovok,
    IdGiata,
  },
  mappingGiata,
}) => {
  const [showDialogMatches, setShowDialogMatcher] = useState(false);

  const deletedText = Deleted ? LABELS.DELETED : '';

  const handleShowDialogMatches = () =>
    setShowDialogMatcher(!showDialogMatches);

  const handleGetAllMatchesById = async () => {
    await hotelsService.getHotelsByGiataIds([Number(IdGiata)]).then(() => {
      handleShowDialogMatches();
    });
  };

  const renderAllMatches = () => {
    if (!Number(IdGiata)) return null;

    return (
      <Button
        theme='second'
        onClick={ handleGetAllMatchesById }
        className={ styles.button_match }
      >
        { LABELS.BUTTON_SHOW_MATCHES }
      </Button>
    );
  };

  const renderDialogAllMatchesIds = () => {
    if (!showDialogMatches || !mappingGiata) return null;

    return (
      <AllMatchesDialog
        onClick={ handleShowDialogMatches }
        mappingGiata={ mappingGiata }
      />
    );
  };

  return (
    <div className={ styles.hotel_item }>
      <LinkToNewTab to={ `/hotels/hotel/${Id}` } key={ Id }>
        <li className={ styles.item }>
          <div className={ styles.item_column }>
            <div className={ styles.img_wrap }>
              <img className={ styles.img } src={ Thumbnail } alt='так себе халупа' />
            </div>
          </div>
          <div className={ styles.text_wrap }>
            <h3 className={ styles.text_item }>{ Name }</h3>
            <div className={ styles.text_item }>
              <b>{ LABELS.CITY }</b>{` ${City}`}
            </div>
            <div className={ styles.text_item }>
              <b>{ LABELS.ADDRESS }</b>{` ${Address}`}
            </div>
            <div className={ styles.text_item }>
              <b>{ LABELS.STARS }</b>{` ${Stars}`}
            </div>
            <div className={ `${styles.text_item} ${styles.text_item_deleted}` }>
              <b>{ deletedText }</b>
            </div>
          </div>
          <div className={ styles.text_wrap }>
            <div className={ styles.text_item }>
              <b>IdAanda:</b>{` ${IdAanda || ''}`}
            </div>
            <div className={ styles.text_item }>
              <b>IdAcadem:</b>{` ${IdAcadem || ''}`}
            </div>
            <div className={ styles.text_item }>
              <b>IdBooking:</b>{` ${IdBooking || ''}`}
            </div>
            <div className={ styles.text_item }>
              <b>IdBronevik:</b>{` ${IdBronevik || ''}`}
            </div>
            <div className={ styles.text_item }>
              <b>IdExpedia:</b>{` ${IdExpedia || ''} `}
            </div>
            <div className={ styles.text_item }>
              <b>IdTripcom:</b>{` ${IdTripcom || ''} `}
            </div>
            <div className={ styles.text_item }>
              <b>IdHotelbook:</b>{` ${IdHotelbook || ''}`}
            </div>
            <div className={ styles.text_item }>
              <b>IdOstrovok:</b>{` ${IdOstrovok || ''}`}
            </div>
            <div className={ styles.text_item }>
              <b>IdGiata:</b>{` ${IdGiata || ''}`}
            </div>
          </div>
        </li>
      </LinkToNewTab>
      { renderAllMatches() }
      { renderDialogAllMatchesIds() }
    </div>
  );
};

HotelItem.propTypes = {
  hotelsService: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  mappingGiata: PropTypes.object.isRequired,
};

export { HotelItem };
