import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Paginate } from 'sw-ui';

import { FlatButton } from '../../../../components/button';
import Input from '../../../../components/input';

import styles from './index.module.css';

const LABELS = {
  SEARCH_PLACEHOLDER: 'Поиск id',
  NOT_FOUND: 'Ничего не найдено',
};

const FIELDS = {
  SEARCH: 'search',
};

const VISIBLE_ON_PAGE = 21;

const AllMatchesDialog = ({
  onClick,
  mappingGiata,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [selectedProvider, setSelectedProvider] = useState('');
  const [selectedProviderIds, setSelectedProviderIds] = useState([]);
  const [filteredSelectedProviderIds, setFilteredSelectedProviderIds] = useState([]);
  const [paginateSelectedProviderIds, setPaginateSelectedProviderIds] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const uniqueProviders = [...new Set(Object.values(mappingGiata).flatMap(obj => Object.keys(obj)))];

  const handleShowIdsByProvider = (provider) => {
    const allIdsEveryProviders = Object.values(mappingGiata)
      .flatMap(obj => Object.entries(obj))
      .reduce((acc, [key, value]) => {
        acc[key] = (acc[key] || []).concat(value);

        return acc;
      }, {});

    setSelectedProviderIds(allIdsEveryProviders[provider]);
    setFilteredSelectedProviderIds(allIdsEveryProviders[provider]);
    setPaginateSelectedProviderIds(allIdsEveryProviders[provider].slice(0, VISIBLE_ON_PAGE));
    setSelectedProvider(provider);
    setSearchValue('');
    setCurrentPage(1);
  };

  useEffect(() => {
    handleShowIdsByProvider(uniqueProviders[0]);
  }, []);

  const handleSearchId = (value) => {
    setSearchValue(value);

    if (!value) {
      setFilteredSelectedProviderIds(selectedProviderIds);
      setCurrentPage(1);

      return setPaginateSelectedProviderIds(selectedProviderIds.slice(0, VISIBLE_ON_PAGE));
    }

    const newList = selectedProviderIds.filter(({ Code }) => Code.includes(value));

    setFilteredSelectedProviderIds(newList);
    setPaginateSelectedProviderIds(newList.slice(0, VISIBLE_ON_PAGE));
  };

  const getVisibleItemsOnPage = current =>
    filteredSelectedProviderIds.slice((current - 1) * VISIBLE_ON_PAGE, current * VISIBLE_ON_PAGE);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    setPaginateSelectedProviderIds(getVisibleItemsOnPage(page));
  };

  const renderButtonTitles = () => uniqueProviders.map((provider, idx) => (
    <div className={ selectedProvider === provider ? styles.selected_provider : '' }>
      <FlatButton
        key={ `${provider}_${idx}` }
        label={ provider }
        onClick={ () => handleShowIdsByProvider(provider) }
      />
    </div>
  ));

  const renderSearchId = () => (
    <Input
      field={ FIELDS.SEARCH }
      value={ searchValue }
      placeholder={ LABELS.SEARCH_PLACEHOLDER }
      onChange={ (e, field, value) => handleSearchId(value) }
    />
  );

  const renderIdsByProviders = () => {
    if (!paginateSelectedProviderIds || !paginateSelectedProviderIds.length) {
      return <span>{ LABELS.NOT_FOUND }</span>;
    }

    return paginateSelectedProviderIds.map(({ Code, Inactive }, idx) => {
      const styleByStatus = Inactive ? styles.inactive_id : styles.active_id;

      return <div key={ `${Code}_${idx}` } className={ styleByStatus }>{ Code }</div>;
    });
  };

  return (
    <Dialog onClick={ onClick } showCloseButton>
      <div className={ styles.dialog }>
        <div className={ styles.titles }>
          { renderButtonTitles() }
        </div>
        { renderSearchId() }
        <div className={ styles.list_ids }>
          { renderIdsByProviders() }
        </div>
        <Paginate
          handlePrevious={ handlePageChange }
          handlePage={ handlePageChange }
          handleNext={ handlePageChange }
          currentPage={ currentPage }
          itemsOnPage={ VISIBLE_ON_PAGE }
          totalItems={ filteredSelectedProviderIds.length }
        />
      </div>
    </Dialog>
  );
};

AllMatchesDialog.propTypes = {
  onClick: PropTypes.func.isRequired,
  mappingGiata: PropTypes.object.isRequired,
};

export { AllMatchesDialog };
