import React, { Component } from 'react';
import PropTypes from 'prop-types';

import lodashReplaces from '../../../bi/utils/lodashReplaces';

import { FIELDS, TEXT } from '../../../bi/constants/hotels';

import Input from '../../../components/input';
import { AddButton } from '../../../components/AddButton';

import { TYPE_ICON } from '../../../components/AddButton/consts';

import styles from '../styles/index.module.scss';

class AmenitiesBlock extends Component {
  static propTypes = {
    hotelsService: PropTypes.object.isRequired,
    Amenities: PropTypes.object.isRequired,
    isNoEdit: PropTypes.bool,
    setRef: PropTypes.func,
    title: PropTypes.string,
    field: PropTypes.string.isRequired,
  };

  static defaultProps = {
    isNoEdit: false,
    setRef: () => {},
    title: '',
  }

  handleAddList = () => {
    const { hotelsService: { changeField }, Amenities, field } = this.props;

    const key = lodashReplaces.uuid();

    const emptyList = { Name: '', Values: { [key]: '' }, key };
    const updatedAmenities = { [key]: emptyList, ...Amenities };

    changeField(updatedAmenities, field);
  }

  handleRemoveList = id => () => {
    const { hotelsService: { changeField }, Amenities, field } = this.props;

    const updatedAmenities = lodashReplaces.omit(Amenities, id);

    changeField(updatedAmenities, field);
  }

  handleAddItem = ({ Values, key }) => () => {
    const { hotelsService: { changeField }, field } = this.props;

    const itemKey = lodashReplaces.uuid();
    const updatedValues = { [itemKey]: { value: '', key: itemKey }, ...Values };
    changeField(updatedValues, `${field}.${key}.${FIELDS.VALUES}`);
  }

  handleRemoveItem = (key, parentKey) => () => {
    const { hotelsService: { changeField }, Amenities, field } = this.props;

    const subList = Amenities[parentKey].Values;
    const updatedSubList = lodashReplaces.omit(subList, key);

    changeField(updatedSubList, `${field}.${parentKey}.${FIELDS.VALUES}`);
  }

  handleChangeInput = (value, field) => {
    const { hotelsService: { changeField }, field: fieldAmeneties } = this.props;

    changeField(value, `${fieldAmeneties}.${field}`);
  };

  renderInput = (valueData, path, label = '') => (
    <Input
      value={ valueData }
      field={ path }
      label={ label }
      onChange={ ({ target: { value } }, field) => this.handleChangeInput(value, field) }
      disabled={ this.props.isNoEdit }
    />
  )

  renderSubListItem = (list, parentKey, key, i) => {
    const value = list[key].Name;
    const path = `${parentKey}.${FIELDS.VALUES}.${key}.${FIELDS.NAME}`;
    const label = `${TEXT.NUMBER_OPTION}${i + 1}`;

    return (
      <li key={ key } className={ styles.item_amenities }>
        {this.renderInput(value, path, label)}
        {this.renderAddOrRemoveButton(this.handleRemoveItem(key, parentKey), TEXT.DELETE_OPTION, TYPE_ICON.CLEAR)}
      </li>
    );
  }

  renderSubList = (list, parentKey) => (
    <ul className={ styles.list }>
      {Object.keys(list).map((key, i) => this.renderSubListItem(list, parentKey, key, i))}
    </ul>
  )

  renderParentElement = (element, label) => {
    const { Name, Values, key } = element;
    const path = `${key}.${FIELDS.NAME}`;

    return (
      <div className={ styles.parent_wrap }>
        <div className={ styles.header }>
          <div className={ styles.subtitle }>{this.renderInput(Name, path, label)}</div>
          {this.renderAddOrRemoveButton(this.handleRemoveList(key), TEXT.DELETE_BLOCK, TYPE_ICON.CLEAR)}
          {this.renderAddOrRemoveButton(this.handleAddItem(element), TEXT.ADD_OPTION)}
        </div>
        {this.renderSubList(Values, key)}
      </div>
    );
  }

  renderAddOrRemoveButton = (handler, text, icon = TYPE_ICON.ADD) => {
    const { isNoEdit } = this.props;

    return (
      <AddButton
        isNoEdit={ isNoEdit }
        icon={ icon }
        text={ isNoEdit ? TEXT.EDIT_INFO : text }
        onClick={ handler }
      />
    );
  };

  render() {
    const { Amenities, setRef, title } = this.props;
    const keys = Object.keys(Amenities);

    return (
      <div ref={ ref => setRef(ref) }>
        <div className={ styles.header }>
          <h2>{ title }</h2>
          {this.renderAddOrRemoveButton(this.handleAddList, TEXT.ADD_BLOCK)}
        </div>
        <div>
          {keys.map((key, i) => <div key={ key }>{this.renderParentElement(Amenities[key], `${TEXT.NUMBER_BLOCK}${i + 1}`)}</div>)}
        </div>
      </div>
    );
  }
}

export default AmenitiesBlock;
